<template>
  <el-container @click.capture="show = false" id="scroll-box">
    <el-header style="">
      <div
        class="header"
        :style="{
          backgroundColor: color,
          color: color == 'transparent' ? '#fff' : '#000000',
        }"
      >
        <img src="@/assets/logo.png" class="logo" alt="" />
        <div class="header-tabs">
          <span
            v-for="(item, index) in tabsList"
            @click="onTabs(index)"
            :style="index == chexTabsIndex ? 'color:#0041F0' : ''"
            :key="index"
            >{{ item }}</span
          >
        </div>
        <div class="header-langs">
          <!-- <i class="el-icon-full-screen quan" @click="screen"></i> -->
          <el-dropdown trigger="click">
            <div class="langs">
              <img src="@/assets/lang.png" alt="" />
              <p
                style="width: 65px"
                :style="{ color: color == 'transparent' ? '#fff' : '#000000' }"
              >
                {{ langTitle }}
              </p>
              <i
                class="el-icon-caret-bottom"
                :style="{color:color=='transparent'?'#fff':'#000000'}"
              ></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in langList"
                :key="index"
                :style="
                  index == langIndex ? 'color:#000000;font-weight: 600;' : ''
                "
              >
                <p @click="onChangeLang(item, index)">{{ item.title }}</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="button" @click="onTabs(5)">
            {{ $t("headerTop.butTitle") }}
          </div>
          <div class="tabs-img">
            <el-dropdown trigger="click">
              <div class="langs">
                <img src="@/assets/more.png" alt="" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in tabsList"
                  :key="index"
                  :style="index == chexTabsIndex ? 'color:#0041F0' : ''"
                >
                  <p @click="onTabs(index)" style="font-family: PingFangSC, PingFang SC;">{{ item }}</p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </el-header>
    <router-view :key="fefKey" />

    <!-- <div style="height:9000px" id="scroll-bo">

    </div> -->
    <footer class="footer-box" @click.capture="show = false" id="footers">
      <div class="footer-top">
        <img src="@/assets/footerlogo.png" class="" alt="" />
        <div>
          <p>{{ $t("footer.contactTitle") }}</p>
          <p>{{ $t("footer.contactMailbox") }}</p>
        </div>
        <div>
          <p>{{ $t("footer.aboutTitle") }}</p>
          <p>{{ $t("footer.aboutDescribe") }}</p>
        </div>
      </div>
      <div class="footer-mid">
        <span v-for="(item, index) in tabsList" :key="index">{{ item }}</span>
      </div>
      <div class="footer-bot">
        <p>Copyrights © 2024 All Rights Reserved by Smart_glocal</p>
      </div>
    </footer>
  </el-container>
</template>

<script>
// import { use118n1 } from 'vue-i18n'
export default {
  data() {
    return {
      isCollapse: false,
      chexTabsIndex: "99", // 顶部类目切换选中项
      langList: [
        {
          title: "中文简体",
          lang: "zh",
        },
        {
          title: "中文繁体",
          lang: "Czh",
        },
        {
          title: "English",
          lang: "en",
        },
      ],
      langIndex: 0,
      langTitle: "中文简体",
      show: false,
      activeStep: "", // 默认选中的锚点的key值
      offsetTop: 0,
      color: "transparent",
      anchorList: [
        { index: 0, id: "aboutUs" },
        { id: "service", index: 1 },
        { id: "advantage", index: 2 },
        { id: "cooperation", index: 3 },
        {
          id: "footers",
          index: 4,
        },
      ],
      fefKey: 1,
    };
  },
  created() {
    console.log(2222222222);
    if (this.$route.fullPath == "/") {
      this.chexTabsIndex = 0;
    }

    // this.ongetRoute();
  },

  watch: {
    $route: {
      handler(to, from) {
        if (this.$store.state) {
          this.chexTabsIndex = this.$store.state.code;
        }
        if (this.$route.fullPath == "/") {
          console.log(this.chexTabsIndex, "-----");
          this.onTabs(this.chexTabsIndex);

          this.$nextTick(() => {
            // 开启滚动条监听
            document.addEventListener("scroll", this.scrollFn, true);

            // let elVal = document.getElementsByClassName('el-drawer__body')[0];
            // this.isScroll = elVal.scrollHeight > elVal.clientHeight;
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const lang = localStorage.getItem("lang");
    if (lang) {
      this.langList.forEach((item, index) => {
        if (item.lang == lang) {
          this.langTitle = item.title;
          this.langIndex = index;
        }
      });
    }

    // if (this.$route.fullPath == "/") {
    //   this.$nextTick(() => {
    //     // 开启滚动条监听
    //     document.addEventListener("scroll", this.scroll, true);

    //     // let elVal = document.getElementsByClassName('el-drawer__body')[0];
    //     // this.isScroll = elVal.scrollHeight > elVal.clientHeight;
    //   });
    // }

    // window.addEventListener("scroll", this.scrollFn, true);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll, false);
  },

  computed: {
    tabsList() {
      return this.$t("headerTop.tabs");
    },
    scrollFn() {
      // 防抖
      return _.debounce(this.scroll, 100);
    },
  },

  methods: {
    onTabs(index) {
      // if (this.chexTabsIndex == index) {
      //   return;
      // }
      this.chexTabsIndex = index;

      switch (index) {
        case 0:
          this.jump("aboutUs");
          break;
        case 1:
          this.jump("service");
          break;
        case 2:
          this.jump("advantage");
          break;
        case 3:
          this.jump("cooperation");
          break;
        case 4:
          this.jump("footers");
          break;

        default:
          break;
      }
    },
    onChangeLang(item, index) {
      localStorage.setItem("lang", item.lang);
      this.langTitle = item.title;
      this.langIndex = index;
      this.$i18n.locale = item.lang;
      location.replace(location);
      this.fefKey++;
      // location.reload();
      // this.$forceUpdate()
    },

    scroll() {
      const box = window.scrollY; // document.getElementById("scroll-box");
      if (box >= 10) {
        this.color = "#fff";
      } else {
        this.color = "transparent";
      }
      // 若当前设置的滚动高度大于实际滚动的高度，即为锚点跳转，不再设置选中的锚点
      if (this.offsetTop > window.scrollX) {
        this.offsetTop = 0;
        return;
      }
      let totalH = 0;
      this.anchorList.some((anchor) => {
        let scrollItem = document.getElementById(anchor.id); // 锚点对应的模块

        if (scrollItem) {
          console.log(anchor.id, "--");
          totalH = scrollItem.offsetTop + scrollItem.clientHeight;

          let judge = box < totalH - 40;

          if (judge) {
            this.chexTabsIndex = anchor.index;
            // console.log(anchor.index,"=====")
            return true;
          }
        }
      });
    },
    jump(id) {
      this.activeStep = id; // 设置选中的锚点为当前点击的
      this.$nextTick(() => {
        const box = document.getElementById("scroll-box");
        let scrollItem = document.getElementById(id);
        console.log(scrollItem, id);
        // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
        if (scrollItem) {
          this.offsetTop = scrollItem.offsetTop;

          window.scrollTo({ top: scrollItem.offsetTop, behavior: "smooth" });
        }
      });

      // box.scrollTo({
      //   top: scrollItem.offsetTop,
      //   behavior: "smooth",
      // });
    },
  },
};
</script>
<style></style>
<style lang="less" scoped>
:deep {
  .el-header {
    padding: 0;
  }

  .el-header {
    background-color: transparent;
    color: #333;
    line-height: 60px;
    text-align: right;
    font-size: 12px;
    position: sticky;
    top: 0;
    z-index: 99999;
    min-width: 1280px;
  }

  .el-aside {
    color: #333;
  }
}

// &::-webkit-scrollbar {
//   display: none;
// }
.header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: transparent;

  .tabs-img {
    display: none;
  }

  .logo {
    width: 164px;
    height: 33px;
  }

  .header-tabs {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;

    width: 600px;
    cursor: pointer;

    span:hover {
      color: #0041f0;
    }
  }

  .header-langs {
    display: flex;
    align-items: center;
    height: 50px;

    .button {
      width: 84px;
      height: 32px;

      border-radius: 16px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      margin-left: 28px;
      cursor: pointer;
    }
  }

  .langs {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;

    // margin-top: 10px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    > p {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      margin-right: 5px;
    }
  }
}

.footer-box {
  width: 100%;
  height: 340px;
  background: #2b2c30;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .footer-top {
    display: flex;
    justify-content: space-between;
    width: 1280px;
    margin: auto;
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 300px;
      margin-left: 300px;
      p {
        width: 100%;
        text-align: left;
      }
    }
    > div:nth-child(3) {
      width: 450px;
    }
    div {
      p:nth-child(1) {
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        line-height: 16px;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 16px;
      }
    }

    > img {
      width: 196px;
      height: 44px;
    }
  }

  .footer-mid {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #c2c2c2;
    line-height: 16px;
    margin-bottom: 30px;

    span {
      margin-right: 20px;
      border-right: 1px solid #c2c2c2;
      padding-right: 10px;
    }
    span:nth-child(5) {
      border: none;
    }
  }

  .footer-bot {
    width: 100%;
    text-align: center;

    p {
      height: 60px;
      line-height: 60px;
      color: #787878;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #787878;
      border-top: 1px solid #787878;

      span {
        margin-left: 20px;
      }
    }

    p:nth-child(1) {
    }
  }
}

@media screen and (max-width: 1080px) {
  :deep {
    .el-header {
      width: 108rem;
      padding: 0;
      min-width: 75rem;
      height: 20rem !important;
      position: sticky;
      top: 0;
      z-index: 99999;
    }

    .el-header {
      background-color: transparent;
      color: #333;
      // line-height: 6rem;
    }

    .el-aside {
      color: #333;
    }
  }

  .header {
    width: 108rem;
    position: relative;
    height: 20rem;
    padding: 0 2rem;
    box-sizing: border-box;
    .header-tabs {
      display: none;
    }

    .tabs-img {
      display: flex;
      align-items: center;
      height: 6rem;
      justify-content: center;

      img {
        margin-right: 0;
        margin-left: 6rem;
        width: 6rem;
        height: 6rem;
      }
    }
    .logo {
      width: 30rem;
      height: 6.8rem;
    }

    .button {
      display: none;
    }
  }

  .footer-box {
    width: 108rem;
    height: 80rem;
    background: #2b2c30;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer-top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 98rem;
      margin: auto;
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 98rem;
        margin-left: 0;
        p {
          width: 100%;
          text-align: left;
        }
      }
      > div:nth-child(3) {
        width: 100%;
      }
      div {
        margin-top: 5rem;
        p:nth-child(1) {
          font-weight: 600;
          font-size: 3.5rem;
          color: #ffffff;
          line-height: 4.1rem;
          margin-bottom: 3rem;
        }
        p:nth-child(2) {
          font-weight: 400;
          font-size: 3rem;
          color: #ffffff;
          line-height: 4.1rem;
        }
      }

      > img {
        width: 19.6rem;
        height: 4.4rem;
        margin-bottom: 2rem;
      }
    }

    .footer-mid {
      width: 100%;
      text-align: center;
      font-weight: 400;
      font-size: 3rem;
      color: #c2c2c2;
      line-height: 3.5rem;
      margin-bottom: 7rem;

      span {
        margin-right: 2rem;

        border-right: 1px solid #c2c2c2;
        padding-right: 1rem;
      }
      span:nth-child(5) {
        border: none;
      }
    }

    .footer-bot {
      width: 100%;
      text-align: center;

      p {
        height: 10rem;
        line-height: 10rem;
        color: #787878;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #787878;
        border-top: 1px solid #787878;

        span {
          margin-left: 20px;
        }
      }

      p:nth-child(1) {
        font-size: 2.5rem;
        color: #787878;
        line-height: 10rem;
      }
    }
  }
}
</style>

