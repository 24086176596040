

export default {
  //中文
  headerTop: {
    // 页面顶部tab列表
    tabs: ['有关我们', '产品及服务', '竞争优势', '合作伙伴', '联系我们'],
    butTitle: '客户登录',
  },
  // 轮播区域文案
  banner: {
    banner1: {
      title: '助您的生意扬帆出海',
      describe: '欢迎来到 Smart Glocal，您在尖端支付技术解决方案上的可靠合作伙伴',
    },
    banner2: {
      title: '为您打造简单便捷的跨境支付体验',
      describe: '欢迎来到 Smart Glocal，您在尖端支付技术解决方案上的可靠合作伙伴',
    },
   
  },
  //   关于我们区域文案
  aboutUs: {
    title: '有关我们',
    keyWord: '我们的使命是简化和优化交易，赋予企业在数字经济中蓬勃发展的能力',
    list: [
        {
          title:'创新与安全',
          describe:'通过我们创新的支付平台，各种规模的企业可以轻松接受各种支付方式，并专注于增长。我们通过采用先进的加密和防欺诈措施，确保敏感的客户数据得到保护，安全是我们的首要考虑。我们的专家团队致力于提供卓越的客户服务和支持，与客户密切合作，了解他们的目标和挑战。让我们一起在数字支付领域中航行，开启新机遇，推动您的业务向前发展。'
        },
        {
          title:'无缝支付体验',
          describe:'在 Smart Glocal，我们为不同行业提供定制的无缝支付体验，涵盖电子商务、零售和酒店业等。我们的解决方案旨在提高效率并提升客户满意度。加入与我们一同改变支付流程的众多企业。探索支付的未来，立即踏上无缝支付之旅。请联系我们，了解更多关于我们的服务以及如何帮助您的业务取得成功的信息。'
        },
        {
          title:'专业团队支持',
          describe:'我们的团队由在科技、金融、运营和合规领域拥有丰富背景的资深专业人员组成。他们拥有深厚的行业知识和对卓越的承诺，为我们的客户提供宝贵的洞察和创新解决方案。'
        }
    ],
  },
  // 产品及服务模块
  service: {
    title: '产品及服务',
    describe: '为不同消费场景量身定制的支付工具，最大程度地匹配消费者的支付需求',
    payment:{
      title:'支付服务',
      list:[
        {
          title:'移动支付',
          describe:'通过我们无缝的移动支付解决方案，简化您的移动交易，让您能够通过智能手机或其他移动设备进行安全、便捷的支付'
        },
        {
          title:'在线支付',
          describe:'通过我们可靠的在线支付服务，简化您的电子商务运营，促进安全的交易，并为您的客户提供无缝的结账体验'
        },
        {
          title:'移动POS',
          describe :'通过我们的移动POS系统，改变您的业务模式，让您随时随地接受支付，提升客户体验，实现高效的交易处理和库存管理能力'
        }
      ]
    },
    finance:{
      title:'金融科技',
      list:[
        {
          title:'客户会员积分管理',
          describe:'通过我们强大的CRM系统，加强客户关系，优化业务流程，实现有效的互动、销售和支持管理'
        },
        {
          title:'电子商务平台',
          describe:'利用我们功能丰富的电子商务平台，推动您的在线业务增长，帮助您管理产品、处理订单，并提供个性化的购物体验'
        },
       
      ]
    }
  },
  // 竞争优势
  advantage: {
    title: '竞争优势',
    describe: '满足中小商户的各交易场景，完美接通主流支付渠道',
    list: [
      {
        title:'多样化的支付方式',
        describe:'支持信用卡、移动钱包和其他支付解决方案等多种支付方式，满足广泛客户群体的偏好'
      },
      {
        title:'国际能力',
        describe:'促进跨境交易，使企业能够接受多种货币的支付，扩大其全球影响力和客户群体'
      },
      {
        title:'分析与报告',
        describe:'提供全面的报告和分析工具，提供有价值的交易数据洞察，帮助企业做出明智决策，优化支付流程'
      },
      {
        title:'定制与个性化',
        describe:'根据特定行业需求定制支付解决方案，提供可定制的功能和品牌选项，提升整体客户体验'
      }
  ],
  },

  // 合作伙伴
  cooperation: {
    title: '合作伙伴',
    describe: '使用熟悉且信赖的付款渠道，获得优质的支付体验',
  

    
  },
  // 底部文案

  footer: {
    contactTitle: '联系我们',
    contactMailbox: '电邮 / Email: info@smart-glocal.com.hk',

    aboutTitle: '有关我们',
  
    aboutDescribe:
      '欢迎来到 Smart Glocal，您在尖端支付技术解决方案上的可靠合作伙伴。我们的使命是简化和优化交易，赋予企业在数字经济中蓬勃发展的能力。',
  },
  
}
