

export default {
  //英文
  headerTop: {
    // 页面顶部tab列表
    tabs: ['About Us', 'Products and Services', 'Our Advantages', 'Partner', 'Contact'],
    butTitle: 'login',
  },
  // 轮播区域文案
  banner: {
    banner1: {
      title: 'Empowering Your Overseas BusinessEmpowering Your Overseas Business',
      describe: 'Welcome to Smart Glocal, your trusted partner for cutting-edge payment technology solutions.',
    },
    banner2: {
      title: 'Simplifying Your Cross-Border Payments Anywhere',
      describe: 'Welcome to Smart Glocal, your trusted partner for cutting-edge payment technology solutions.',
    },
   
  },
  //   关于我们区域文案
  aboutUs: {
    title: 'About Us',
    keyWord: 'We strive to simplify and optimize transactions, empowering businesses to thrive in the digital economy',
    list: [
        {
          title:'Innovation and safety',
          describe:'Our mission is to simplify and optimize transactions, empowering businesses to thrive in the digital economy. With our innovative payment platform, businesses of all sizes can effortlessly accept various payment methods and focus on growth. We prioritize security by employing advanced encryption and fraud prevention measures, ensuring the protection of sensitive customer data. Our team of experts is dedicated to providing exceptional customer service and support, working closely with clients to understand their goals and challenges. Together, we can navigate the digital payment landscape, unlock new opportunities, and drive your business forward.'
        },
        {
          title:'Seamless payment experience',
          describe:'At Smart Glocal, we offer seamless payment experiences tailored to different industries, from e-commerce to retail and hospitality. Our solutions are designed to increase efficiency and enhance customer satisfaction. Join countless businesses that have transformed their payment processes with us. Discover the future of payments and embark on a seamless payment journey today. Contact us to learn more about our services and how we can help your business succeed.'
        },
        {
          title:'Professional support',
          describe:'Our team comprises seasoned professionals with extensive backgrounds in technology, finance, operations and compliance. With their deep industry knowledge and commitment to excellence, they provide invaluable insights and innovative solutions for our clients.'
        }
    ],
  },
  // 产品及服务模块
  service: {
    title: 'Products and Services',
    describe: 'payment solutions for different consumption scenarios to match consumers payment needs to the greatest extent',
    payment:{
      title:'Payment Services',
      list:[
        {
          title:'Mobile Payment',
          describe:'Simplify transactions on the go with our seamless mobile payment solution, enabling secure and convenient payments through smartphones or other mobile devices.'
        },
        {
          title:'Online Payment',
          describe:'Streamline your e-commerce operations with our reliable online payment service, facilitating secure transactions and providing a seamless checkout experience for your customers.'
        },
        {
          title:'Mobile POS',
          describe :'Revolutionize your business with our mobile point-of-sale system, empowering you to accept payments anytime, anywhere, and enhance customer experience with efficient transaction processing and inventory management capabilities.'
        }
      ]
    },
    finance:{
      title:'Fintech',
      list:[
        {
          title:'Client Relationship Management (CRM)',
          describe:'Strengthen customer relationships and optimize business processes with our powerful CRM system, enabling effective management of interactions, sales, and support.'
        },
        {
          title:'eCommence Platform',
          describe:'Power your online business and drive growth with our feature-rich e-commerce platform, empowering you to manage products, process orders, and deliver personalized shopping experiences.'
        },
       
      ]
    }
  },
  // 竞争优势
  advantage: {
    title: 'Our Advantages',
    describe: 'To Meet various transaction scenarios for SMEs and seamlessly connect to mainstream payment channels',
    list: [
      {
        title:'Wide Range of Payment Options',
        describe:'Supporting diverse payment methods, including credit cards, mobile wallets, and alternative payment solutions, catering to the preferences of a broad customer base.'
      },
      {
        title:'International Capabilities',
        describe:'Facilitating cross-border transactions and enabling businesses to accept payments in multiple currencies, expanding their global reach and customer base.'
      },
      {
        title:'Analytics and Reporting',
        describe:'Offering comprehensive reporting and analytics tools that provide valuable insights into transaction data, helping businesses make informed decisions and optimize their payment processes.'
      },
      {
        title:'Customization and Personalization',
        describe:'Tailoring payment solutions to specific industry requirements, offering customizable features and branding options to enhance the overall customer experience.'
      }
  ],
  },

  // 合作伙伴
  cooperation: {
    title: 'Partner',
    describe: 'Get a great payment experience using familiar and trusted payment channels',
  

    
  },
  // 底部文案

  footer: {
    contactTitle: 'Contact',
    contactMailbox: 'Email: info@smart-glocal.com.hk',

    aboutTitle: 'About Us',
  
    aboutDescribe:
      'Welcome to Smart Glocal, your trusted partner for cutting-edge payment technology solutions.',
  },
  
}
